<template>
  <q-page padding>
    <div class="column items-center">
      <template v-if="!hasRole('guest')">
        <div class="col q-py-xs">
          <m-image
            :src="user.picture"
            class="avatar avatar-toolbar cursor-pointer"
          />
        </div>
        <div class="col text-weight-medium">
          {{ user.display_name }}
        </div>
        <div v-if="!['gohi', 'derbygo'].includes(partner.slug) && hasRole(['member', 'middleoffice'])" class="col q-py-xs">
          {{ budgetLabel }}
        </div>
        <m-current-month
          v-if="!['gohi', 'derbygo'].includes(partner.slug) && hasRole(['member', 'middleoffice'])"
          :spend="spend"
          :budget="budget"
          :within-budget="withinBudget"
        />
      </template>
      <m-guest-blocker v-else />
      <div class="col full-width q-mt-md">
        <q-list>
          <q-item
            v-for="menu in filteredMenu"
            :key="menu.icon"
            v-ripple
            clickable
            :to="hasRole('guest') && (menu.link.name !== 'Logout') ? '' : menu.link"
          >
            <q-item-section avatar>
              <q-icon :name="menu.icon" />
            </q-item-section>
            <q-item-section>{{ menu.label }}</q-item-section>
          </q-item>
          <q-item>
            <q-item-section v-if="isDerby" class="q-mt-md">
              <small class="self-center text-grey-7 q-mb-md">By</small>
              <img :src="require('assets/Kinto_Blue_height_30.png')" height="30px" class="self-center q-mb-md">
              <small class="row self-center text-grey-7 q-mb-xs text-center powered">
                {{ $t('powered_by_partner', {partner: 'DerbyGo', intermediate: 'KINTO'}) }}
              </small>
            </q-item-section>
            <q-item-section v-else class="q-mt-md">
              <small class="row self-center text-grey-5 q-mb-xs">{{ $t('powered_by') }}</small>
              <img :src="require('assets/mobilleo-logo-blue.png')" height="30px" class="self-center">
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
  </q-page>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { get } from 'api/dashboard'
import { MGuestBlocker } from 'components'

export default {
  name: 'Profile',
  components: { MGuestBlocker },
  mixins: [authentication],
  data () {
    return {
      spend: null,
      budget: null,
      overBudget: null,
      profileMenu: [
        {
          icon: 'fa fa-user',
          label: this.$t('account_settings'),
          link: { hash: '/user-settings/account', query: { label: 'home' } }
        },
        {
          icon: 'grade',
          label: this.$t('favourite_addresses'),
          link: { hash: '/user-settings/address-list' }
        },
        {
          icon: 'far fa-passport',
          label: this.$t('travel_documents'),
          link: { hash: '/user-settings/passport' }
        },
        {
          icon: 'fas fa-credit-card',
          label: this.$tc('payment_card.card', 2),
          link: { hash: '/user-settings/payments' }
        },
        {
          icon: 'far fa-car',
          label: this.$t('carclub.membership.membership'),
          link: { hash: '/user-settings/carclub' }
        },
        {
          icon: 'far fa-abacus',
          label: this.$tc('mobility_budget.default', 2),
          link: { hash: '/mobility-budget' }
        },
        {
          icon: 'far fa-cogs',
          label: this.$t('transport_preferences'),
          link: { hash: '/user-settings/travel-policy' }
        },
        {
          icon: 'fas fa-question',
          label: this.$t('contact_us'),
          link: { hash: '/contact' }
        },
        {
          icon: 'far fa-sign-out',
          label: this.$t('sign_out'),
          link: { name: 'Logout' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['ui', 'user', 'organisation', 'partner']),
    withinBudget () {
      if (!this.spend && !this.budget) return true
      return this.spend?.amount <= this.budget?.amount
    },
    budgetLabel () {
      return this.withinBudget
        ? this.$t('you_are_within_budget')
        : this.$t('you_are_over_budget')
    },
    filteredMenu () {
      if (['gohi'].includes(this.partner.slug) || !this.hasRole(['member', 'middleoffice'])) {
        return this.profileMenu.filter(item => item.label !== this.$tc('mobility_budget.default', 2))
      }
      if (['derbygo'].includes(this.partner.slug)) {
        return this.profileMenu.filter(item => {
          return ![
            this.$tc('mobility_budget.default', 2),
            this.$t('carclub.membership.membership'),
            this.$t('travel_documents'),
            this.$t('transport_preferences')
          ].includes(item.label)
        })
      }
      return this.profileMenu.filter(item => ![this.$t('contact_us')].includes(item.label))
    },
    isDerby () {
      return this.$store.getters['partner'].slug === 'derbygo'
    }
  },
  async created () {
    const response = await get()
    this.spend = response.data.spend
    this.budget = response.data.current_month_budget
  }
}
</script>

<style lang="stylus" scoped>
.powered
  padding: 0 75px
</style>
